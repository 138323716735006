@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .font-condensed {
    font-stretch: condensed;
  }
  .font-expend {
    font-stretch: expanded;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.ag-cell {
  font-size: 9pt;
  padding-left: 5px;
  padding-right: 5px;
}

.header-wrap-text .ag-header-cell-label {
  white-space: normal;
}

.static-cell {
  font-size: 9pt;
  text-align: center;
}

.number-cell {
  font-size: 9pt;
  text-align: right;
  padding-left: 2px;
  padding-right: 5px;
}

.red {
  color: red;
  font-weight: 600;
}

.blue {
  color: cornflowerblue;
  font-weight: 600;
}
